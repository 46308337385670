<template>
  <div>
    <!-- modal upload medical rest -->
    <b-modal
      ref="modal_justification"
      size="lg"
      header-bg-variant="primary"
      header-text-variant="light"
      body-bg-variant="ligth"
      body-text-variant="dark"
      footer-bg-variant="ligth"
      footer-text-variant="dark"
      no-close-on-backdrop
      class="border-0 border-white"
      @hidden="$emit('hidden')"
    >
      <template #modal-title>
        <div class="d-flex justify-content-start align-items-center text-white">
          <tabler-icon icon="UploadIcon" size="20" class="mr-1"></tabler-icon>
          <h4 class="m-0 p-0 text-white">
            {{ action }} Justification of {{ dataJustification.employee_name }}
          </h4>
        </div>
      </template>
      <b-container class="p-1">
        <b-row>
          <b-col cols="6">
            <validation-observer ref="formJustification">
              <!-- select con el type of justification -->
              <validation-provider
                v-slot="{ errors }"
                name="type_justification"
                rules="required"
              >
                <b-form-group label="Type of justification">
                  <b-form-select
                    v-model="justification.type"
                    :options="typeJustification"
                    :disabled="action == 'Show' ? true : false"
                    placeholder="Select type of justification"
                    :state="errors[0] ? false : null"
                    :class="errors[0] ? 'border-danger' : ''"
                  ></b-form-select>
                </b-form-group>
              </validation-provider>

              <b-form-group label="File">
                <b-form-file
                  ref="file"
                  v-model="fileToUpload"
                  @change="fileFill"
                  placeholder="Upload file justification"
                  accept=".jpg, .png, .jpeg, .pdf"
                />
                <b-button @click="clearFiles" variant="danger" class="mt-1"
                  >Reset</b-button
                >
              </b-form-group>

              <validation-provider
                v-slot="{ errors }"
                name="date"
                rules="required"
              >
                <b-form-group label="Date">
                  <kendo-datepicker
                    id="date"
                    :first-day-of-week="1"
                    v-model="justification.date"
                    v-mask="'##/##/####'"
                    :disabled="action == 'Show' ? true : false"
                    placeholder="MM/DD/YYYY"
                    :format="'MM/dd/yyyy'"
                    :state="errors[0] ? false : null"
                    :class="errors[0] ? 'border-danger' : ''"
                    :min="minDate"
                    class="leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date"
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="motive"
                rules="required"
              >
                <b-form-group label="Motive">
                  <b-form-textarea
                    class="text-area-box roboto-class"
                    id="justification_motive"
                    name="text"
                    cols="30"
                    rows="5"
                    maxLength="255"
                    :disabled="action == 'Show' ? true : false"
                    v-model="justification.motive"
                    placeholder="What is the reason?"
                    :state="errors[0] ? false : null"
                    :class="errors[0] ? 'border-danger' : ''"
                  ></b-form-textarea>
                </b-form-group>
              </validation-provider>
            </validation-observer>
          </b-col>
          <b-col cols="6" class="pl-1" style="margin: auto">
            <a
              v-if="action.toLowerCase() != 'add' && justification.fileImg"
              :href="justification.fileImg"
              target="_blank"
              class="bg-primary text-white rounded text-decoration-none"
              style="padding: 5px 10px"
            >
              View file
              <feather-icon icon="EyeIcon"></feather-icon>
            </a>
            <img
              v-if="isImage | !justification.fileImg"
              :src="
                justification.fileImg
                  ? justification.fileImg
                  : 'https://programacion.net/files/article/20160819020822_image-not-found.png'
              "
              alt="Justification"
              width="100%"
              height="100%"
              style="margin: auto; height: 300px"
            />
            <iframe
              style="margin: auto; height: 300px"
              title="Justification"
              v-else
              :src="justification.fileImg"
              frameborder="0"
              width="100%"
              height="100%"
            ></iframe>
          </b-col>
        </b-row>

        <b-row v-if="action != 'Show'" class="mt-1">
          <b-col>
            <h4>Absences</h4>
            <hr />
            <template v-if="absenceEmployee.length">
              <div
                class="scroll-container"
                style="height: 250px; overflow-y: auto"
              >
                <b-list-group>
                  <template v-for="data in absenceEmployee">
                    <b-list-group-item
                      :key="data.date_mark"
                      v-if="data.overtime > 0"
                    >
                      <b-row>
                        <b-col cols="6">
                          <div
                            class="text-center d-flex justify-content-center align-items-center"
                            style="height: 100%"
                          >
                            <span>{{ data.date_mark | myDateGlobal }}</span>
                          </div>
                        </b-col>
                        <b-col cols="6">
                          <div
                            class="text-center d-flex justify-content-center align-items-center"
                            style="height: 100%"
                          >
                            <small class="text-danger"
                              >{{ data.overtime }} hours missing</small
                            >
                          </div>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </template>
                </b-list-group>
              </div>
            </template>

            <template v-else>
              <b-card
                class="shadow-none"
                :style="
                  !this.isDarkSkin
                    ? 'background-color: #d6eaf8'
                    : 'background-color: #3E3E3F'
                "
              >
                <b-container
                  class="d-flex justify-content-center align-items-center"
                  style="height: 150px"
                >
                  <div class="text-center" style="color: #2980b9">
                    <tabler-icon
                      icon="MoodHappyIcon"
                      size="40"
                      class="mr-1 mb-2"
                    />
                    <br />
                    <span style="font-size: 25px"
                      >This employee has no absence hours !!!</span
                    >
                  </div>
                </b-container>
              </b-card>
            </template>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-around align-items-center">
          <!-- <b-button variant="danger" @click="closeModal">Close</b-button> -->
          <b-button
            v-if="action != 'Show'"
            variant="primary"
            @click="insertJustification"
            >Save</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import serviceDashboard from "@/views/assistance/views/dashboard/services/dashboard.services.js";
import moment from "moment";

export default {
  props: {
    dataJustification: {
      default: () => {
        return {};
      },
    },
    action: null,
    date_overtime: {
      default: "",
    },
    employee_id: {
      default: 0,
    },
  },
  data() {
    return {
      fileToUpload: null,
      justification: {
        image: "",
        fileImg: "",
        date: "",
        motive: "",
        type: {
          text: "Select Justification",
          value: 0,
        },
        extension: "",
      },
      typeJustification: [
        { text: "-- Select --", value: null },
        { text: "Faults", value: 1 },
        { text: "Permission", value: 2 },
        { text: "Late", value: 3 },
      ],
      absenceEmployee: [],
    };
  },
  computed: {
    isImage() {
      const extension = ["png", "jpg", "jpeg"];
      return extension.includes(this.justification.extension);
    },
    isUpdateJustification() {
      return this.dataJustification.id ? true : false;
    },
    minDate() {
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      return date;
    },
  },
  mounted() {
    if (this.action.toLowerCase() == "show") {
      this.typeJustification.push({ text: "Other", value: 7 });
    }
    this.toggleModal("modal_justification");
    this.getAbsence();

    if (
      this.dataJustification.start_date != "" &&
      this.dataJustification.start_date != null
    ) {
      this.justification.date = moment(
        this.dataJustification.start_date,
        "YYYY-MM-DD"
      ).format("MM-DD-YYYY");
    }

    this.justification.motive = this.dataJustification.observation;
    this.justification.fileImg = this.dataJustification.image;
    this.justification.type = this.dataJustification.type_justification;
    this.justification.extension = this.dataJustification.extension;
  },
  methods: {
    async getAbsence() {
      try {
        const params = {
          user_id: this.employee_id,
          filterDay: null,
          filterWeekStart: null,
          filterWeekEnd: null,
          filterMonth: moment().format("M"),
          filterYear: moment().format("YYYY"),
        };
        const data = await serviceDashboard.getDetailsAttendance(params);
        if (data.status === 200) {
          const today = moment().startOf("day");
          this.absenceEmployee = data.data.data.filter((item) =>
            moment(item.date_mark).isBefore(today, "day")
          );
          return this.absenceEmployee || [];
        }
      } catch (error) {
        this.showErrorSwal(error);
        console.error(error);
      }
    },

    clearFiles() {
      this.$refs["file"].reset();
      this.justification.fileImg = "";
    },

    async insertJustification() {
      try {
        const result = await this.$refs.formJustification.validate();
        if (!result) {
          return;
        }
        this.addPreloader();
        const params = {
          user_id: this.employee_id,
          image: this.justification.image,
          fileImg: this.justification.fileImg,
          start_date: moment(this.justification.date, "MM-DD-YYYY").format(
            "YYYY-MM-DD"
          ),
          end_date: moment(this.justification.date, "MM-DD-YYYY").format(
            "YYYY-MM-DD"
          ),
          motive: this.justification.motive,
          type_justification: this.justification.type,
          date_overtime: this.date_overtime,
          id_justification: this.dataJustification.justification
            ? this.dataJustification.justification
            : null,
        };
        const { data } = params.id_justification
          ? await serviceDashboard.updateJustification(params)
          : await serviceDashboard.insertJustification(params);
        if (data.state == 1) {
          this.showSuccessSwal(
            "Success",
            "Justification registered successfully"
          );
          this.$refs.formJustification.reset();
          this.$emit("refreshData");
          this.closeModal();
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
        console.log(error);
      }
    },
    closeModal() {
      this.fileToUpload = null;
      this.$emit("hidden");
    },
    async fileFill(event) {
      //imagen, pdf, word
      const accept = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "application/pdf",
        // "application/msword",
        // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      let image = event.srcElement.files[0];
      if (image) {
        if (!accept.includes(image.type)) {
          this.showInfoSwal("Only image or pdf Allowed", "Warning");
        } else {
          this.justification.image = image.name;
          this.justification.extension = image.name.split(".").pop();
          let reader = new FileReader();
          reader.readAsDataURL(image);
          reader.onload = () => (this.justification.fileImg = reader.result);
        }
      }
    },
  },
};
</script>