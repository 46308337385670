<template>
  <div class="options">
    <div
      v-for="(option,index) in options"
      :key="`options-${option}-${index}`"
      class="option-container"
    >
      <div
        :id="`option-${option.value}`"
        class="option-selector"
        :style="selected === option.value ? { backgroundColor: '#0057FF' } : {backgroundColor: '#fff'}"
        @click="selectOption(option.value)"
      >
        <svg
          v-if="selected === option.value"
          width="11"
          height="9"
          viewBox="0 0 11 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 1L3.8125 8L1 4.81818"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div class="option-label">
        <span class="option-label-text">{{ option.text }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CustomizedRadioGroup',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    selectedOption: {
      type: String,
      default: '',
    },

  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
    if (this.options.find(option => option.value === this.selectedOption) !== undefined) {
      this.selected = this.selectedOption;
    } else {
      this.selected = this.options[0]?.value || '';
    }
  },
  methods: {
    selectOption(value) {
      this.selected = value;
      this.$emit('changeOption', value);
    },
  },
};
</script>
<style scoped>
.options{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex-direction: row;
}.option-container{
    display:flex;
    align-items: center;
    flex-direction: row;
    margin-right: 2em;
}.option.container:last-child{
    margin-right: 0;
}.option-label-text{
    font-size:16px;
    font-weight: 300;
    font-family: 'Montserrat', sans-serif;
    line-height: 12px;
}
.option-selector{
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #0057FF;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
