<template>
  <b-modal
      ref="rejectedJustifications"
      v-model="onControl"
      :size="'xl'"
      title="Justifications disapproved by Chief, RRHH or Management"
      hide-footer
      no-close-on-backdrop
      scrollable
      @hidden="onClose"
  >
    <!--    status 2 for disapproved justifications / overtime-->
    <table-overtime-approved
        :data-employee="employeeData"
        :valueFilterDay="filtersDate.filterDay"
        :valueFilterWeekStart="filtersDate.filterWeekStart"
        :valueFilterWeekEnd="filtersDate.filterWeekEnd"
        :valueFilterMonth="filtersDate.filterMonth"
        :valueFilterYear="filtersDate.filterYear"
        :status="2"
    />
  </b-modal>
</template>

<script>
import TableOvertimeApproved from "@/views/assistance/views/dashboard/components/Overtime/TableOvertimeApproved.vue";
export default {
  components: {
    TableOvertimeApproved
  },
  props: {
    filtersDate: {
      type: Object,
      default: null,
    },
    employeeData: {
      type: Object,
      default: null,
    }
  },
  name: "ModalRejectedJustifications",
  data() {
    return {
      onControl: false,
    }
  },
  async created() {
    this.onControl = true
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
  }
}
</script>

<style scoped>

</style>