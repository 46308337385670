var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal_justification",staticClass:"border-0 border-white",attrs:{"size":"lg","header-bg-variant":"primary","header-text-variant":"light","body-bg-variant":"ligth","body-text-variant":"dark","footer-bg-variant":"ligth","footer-text-variant":"dark","no-close-on-backdrop":""},on:{"hidden":function($event){return _vm.$emit('hidden')}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-start align-items-center text-white"},[_c('tabler-icon',{staticClass:"mr-1",attrs:{"icon":"UploadIcon","size":"20"}}),_c('h4',{staticClass:"m-0 p-0 text-white"},[_vm._v(" "+_vm._s(_vm.action)+" Justification of "+_vm._s(_vm.dataJustification.employee_name)+" ")])],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 d-flex justify-content-around align-items-center"},[(_vm.action != 'Show')?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.insertJustification}},[_vm._v("Save")]):_vm._e()],1)]},proxy:true}])},[_c('b-container',{staticClass:"p-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-observer',{ref:"formJustification"},[_c('validation-provider',{attrs:{"name":"type_justification","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Type of justification"}},[_c('b-form-select',{class:errors[0] ? 'border-danger' : '',attrs:{"options":_vm.typeJustification,"disabled":_vm.action == 'Show' ? true : false,"placeholder":"Select type of justification","state":errors[0] ? false : null},model:{value:(_vm.justification.type),callback:function ($$v) {_vm.$set(_vm.justification, "type", $$v)},expression:"justification.type"}})],1)]}}])}),_c('b-form-group',{attrs:{"label":"File"}},[_c('b-form-file',{ref:"file",attrs:{"placeholder":"Upload file justification","accept":".jpg, .png, .jpeg, .pdf"},on:{"change":_vm.fileFill},model:{value:(_vm.fileToUpload),callback:function ($$v) {_vm.fileToUpload=$$v},expression:"fileToUpload"}}),_c('b-button',{staticClass:"mt-1",attrs:{"variant":"danger"},on:{"click":_vm.clearFiles}},[_vm._v("Reset")])],1),_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Date"}},[_c('kendo-datepicker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date",class:errors[0] ? 'border-danger' : '',attrs:{"id":"date","first-day-of-week":1,"disabled":_vm.action == 'Show' ? true : false,"placeholder":"MM/DD/YYYY","format":'MM/dd/yyyy',"state":errors[0] ? false : null,"min":_vm.minDate},model:{value:(_vm.justification.date),callback:function ($$v) {_vm.$set(_vm.justification, "date", $$v)},expression:"justification.date"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"motive","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Motive"}},[_c('b-form-textarea',{staticClass:"text-area-box roboto-class",class:errors[0] ? 'border-danger' : '',attrs:{"id":"justification_motive","name":"text","cols":"30","rows":"5","maxLength":"255","disabled":_vm.action == 'Show' ? true : false,"placeholder":"What is the reason?","state":errors[0] ? false : null},model:{value:(_vm.justification.motive),callback:function ($$v) {_vm.$set(_vm.justification, "motive", $$v)},expression:"justification.motive"}})],1)]}}])})],1)],1),_c('b-col',{staticClass:"pl-1",staticStyle:{"margin":"auto"},attrs:{"cols":"6"}},[(_vm.action.toLowerCase() != 'add' && _vm.justification.fileImg)?_c('a',{staticClass:"bg-primary text-white rounded text-decoration-none",staticStyle:{"padding":"5px 10px"},attrs:{"href":_vm.justification.fileImg,"target":"_blank"}},[_vm._v(" View file "),_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1):_vm._e(),(_vm.isImage | !_vm.justification.fileImg)?_c('img',{staticStyle:{"margin":"auto","height":"300px"},attrs:{"src":_vm.justification.fileImg
                ? _vm.justification.fileImg
                : 'https://programacion.net/files/article/20160819020822_image-not-found.png',"alt":"Justification","width":"100%","height":"100%"}}):_c('iframe',{staticStyle:{"margin":"auto","height":"300px"},attrs:{"title":"Justification","src":_vm.justification.fileImg,"frameborder":"0","width":"100%","height":"100%"}})])],1),(_vm.action != 'Show')?_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('h4',[_vm._v("Absences")]),_c('hr'),(_vm.absenceEmployee.length)?[_c('div',{staticClass:"scroll-container",staticStyle:{"height":"250px","overflow-y":"auto"}},[_c('b-list-group',[_vm._l((_vm.absenceEmployee),function(data){return [(data.overtime > 0)?_c('b-list-group-item',{key:data.date_mark},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-center d-flex justify-content-center align-items-center",staticStyle:{"height":"100%"}},[_c('span',[_vm._v(_vm._s(_vm._f("myDateGlobal")(data.date_mark)))])])]),_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-center d-flex justify-content-center align-items-center",staticStyle:{"height":"100%"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(data.overtime)+" hours missing")])])])],1)],1):_vm._e()]})],2)],1)]:[_c('b-card',{staticClass:"shadow-none",style:(!this.isDarkSkin
                  ? 'background-color: #d6eaf8'
                  : 'background-color: #3E3E3F')},[_c('b-container',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"height":"150px"}},[_c('div',{staticClass:"text-center",staticStyle:{"color":"#2980b9"}},[_c('tabler-icon',{staticClass:"mr-1 mb-2",attrs:{"icon":"MoodHappyIcon","size":"40"}}),_c('br'),_c('span',{staticStyle:{"font-size":"25px"}},[_vm._v("This employee has no absence hours !!!")])],1)])],1)]],2)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }