export default [

    {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "From",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric"
        },
        cols: 6
    },
    {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "To",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric"
        },
        cols: 6
    },

    {
        type: 'select',
        margin: true,
        showLabel: true,
        label: 'Type Justification',
        model: null,
        options: [
            { value: null, label: 'All' },
            { value: 1, label: 'Faults' },
            { value: 2, label: 'Permission' },
            { value: 3, label: 'Late' },
            { value: 4, label: 'Medical Rest' },
            { value: 6, label: 'Remote' },
            { value: 7, other: 'Other' }
        ],
        reduce: 'value',
        selectText: 'label',
        cols: 12,
    },
    {
        type: 'select',
        margin: true,
        showLabel: true,
        label: 'Status',
        model: null,
        options: [
            { value: null, label: 'All' },
            { value: 3, label: 'Rejected By Chief' },
            { value: 5, label: 'Rejected By Managment' },
            { value: 7, label: 'Rejected By Human Talent' },

        ],
        reduce: 'value',
        selectText: 'label',
        cols: 12,
    },
]
