<template>
  <div>
    <div>
      <h3 class="text-center">{{ titleModal }}</h3>
    </div>
    <filter-slot
      :filter="dataEmployee!=null&&user=='chief'?[]:filter"
      :filter-principal="filterPrincipal"
      :no-visible-principal-filter="dataEmployee!=null?true:false"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :change-columns-by-client="true"
      @reload="$refs['refOvertimeApproved'].refresh()"
    >
      <b-table
        slot="table"
        ref="refOvertimeApproved"
        :items="overtimeApprovedProvider"
        :fields="visibleFields"
        primary-key="id"
        table-class="text-nowrap"
        sticky-header="45vh"
        show-empty
        :busy.sync="isBusy"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(employee_name)="data">
          <div class="d-flex justify-content-center">
            <span>
              {{
                 data.item.employee_name
              }}
            </span>
          </div>
        </template>
        <template #cell(overtime)="data">
          <div class="d-flex justify-content-center">
            <span
              class="text-success text-center"
              style="padding: 0 10px; border-radius: 5px"
            >
              {{ data.item.overtime }}
            </span>
          </div>
        </template>
        <template #cell(date_mark)="data">
          <div v-if="data.item.date_mark">
            <div
              v-for="(date, index) in JSON.parse(data.item.date_mark)"
              v-show="data.item.opened || index < 3"
              :key="index"
              class="text-center"
            >
              <span>{{ date | myGlobal }}</span>
            </div>
            <div class="d-flex justify-content-center" v-if="data.item.opened==false && JSON.parse(data.item.date_mark).length > 3">
              <a style="cursor: pointer" href="#" @click="data.item.opened = true"
                >See more...</a
              >
            </div>
            <div  class="d-flex justify-content-center"  v-if="data.item.opened==true && JSON.parse(data.item.date_mark).length > 3">
              <a style="cursor: pointer" href="#" @click="data.item.opened = false"
                >See less...</a
              >
            </div>
          </div>
        </template>
        <template #cell(justification)="data">
          <div class="w-100 text-center" v-if="data.item.type_justification === null">Not justificated hours</div>
          <div v-else>
            <div
                v-if="!(['Remote', 'Other'].includes(data.item.name_type_justification))
            "
                class="d-flex justify-content-center"
            >
            <span
                style="text-align: center;"
                :class="data.item.type_justification != 5?'cursor-pointer':''"
                @click="
                data.item.type_justification === 4
                  ? showMedicalRest(data.item)
                  : showJustification(data.item)
              "
            >
              <tabler-icon
                  v-if="data.item.type_justification != 5"
                  icon="FileIcon"
                  size="21"
                  badge-classes="bg-primary"
              />
              <br />
              {{ data.item.name_type_justification }}
            </span>
            </div>
            <div v-else class="d-flex justify-content-center">
            <span style="text-align: center">
              {{ data.item.observation }}
            </span>
            </div>
          </div>
        </template>
        <template #cell(observation)="data">
          <div class="text-center">
            <span>
          {{ data.item.observation === '' || data.item.observation === null
                ? 'Rejected'
                : data.item.observation }}
            </span>
          </div>
        </template>
        <template #cell(tracking)="data">
          <div class="w-100 text-center" v-if="data.item.type_justification === null">No tracking available</div>
          <div v-else class="d-flex justify-content-center">
            <tabler-icon
              icon="AlignLeftIcon"
              size="20"
              class="cursor-pointer text-primary"
              @click="showModalTracking(data.item.id_justificationb)"
            ></tabler-icon>
          </div>
        </template>
        <template #cell(motive)="data">
          <div class="d-flex justify-content-center">
            <span>{{data.item.motive}}</span>
          </div>
        </template>
        <template #cell(process_overtime)="data">
          <div class="d-flex justify-content-center">
            <b-badge :variant="returnProcessOvertimeColor(data.item.process_overtime)">
              {{ data.item.process_overtime_description }}
            </b-badge>
          </div>
        </template>
        <template #cell(actions)="data">
          <feather-icon
              v-b-tooltip.hover.top="'Approve justification'"
              icon="ThumbsUpIcon"
              size="20"
              class="cursor-pointer text-success mr-1"
              @click="approveJustification(data.item.id_justificationb)"
            />
            <feather-icon
              v-b-tooltip.hover.top="'Disapprove justification'"
              icon="ThumbsDownIcon"
              size="18"
              class="cursor-pointer text-danger mr-1"
              @click="disapproveJustification(data.item.id_justificationb)"
            />
        </template>
      </b-table>
    </filter-slot>
    <modal-tracking-medical-rest
      v-if="showTracking"
      :idJustification="idJustification"
      @closeModal="closeTrackingMedicalRest"
    >
    </modal-tracking-medical-rest>
    <modal-justification
      v-if="showUpload"
      :employee_id="userIdSelected"
      :dataJustification="dataJustification"
      :action="actionJustification"
      :date_overtime="null"
      @hidden="closeModalJustification"
      @refreshData="refreshTable"
    ></modal-justification>
    <modal-upload-medical-rest
      v-if="showUploadMedicalRest"
      :employee_id="userIdSelected"
      :dataJustification="dataJustification"
      :action="'show'"
      @closeModal="closeUploadMedicalRest"
      @refreshData="refreshTable"
    ></modal-upload-medical-rest>
  </div>
</template>


<script>
import DashboardServices from "@/views/assistance/views/dashboard/services/dashboard.services.js";
import ModalJustification from "@/views/assistance/views/dashboard/components/ModalJustification.vue";
import ModalTrackingMedicalRest from "@/views/assistance/views/dashboard/components/medicalRest/modalTrackingMedicalRest.vue";
import ModalUploadMedicalRest from "@/views/assistance/views/dashboard/components/medicalRest/modalUploadMedicalRest.vue";
import filtersDataRejected from "@/views/assistance/views/dashboard/components/Overtime/data/filters.rejected.data.js";
import filtersDataApproved from "@/views/assistance/views/dashboard/components/Overtime/data/filters.approved.data.js";
import moment from "moment";

export default {
  components: {
    ModalJustification,
    ModalTrackingMedicalRest,
    ModalUploadMedicalRest,
  },
  props: {
    dataEmployee: null,
    valueFilterDay: null,
    valueFilterWeekStart: null,
    valueFilterWeekEnd: null,
    valueFilterMonth: null,
    valueFilterYear: null,
    status: null, // 1: approved statuses (2,4,6), 2: disapproved statuses (3,5,7), for more info of the meaning of that statuses go to the table process_overtime
    user:null
},
  computed: {
    visibleFields() {
      return this.fieldsApproved.filter((field) => field.visible);
    },
    moduleId(){
      return this.$route.matched[0].meta.module;
    },
    isRRHH(){
      return this.moduleId === 17
    },
    isManagement(){
      return this.moduleId === 16
    },
    isRRHHorManagement(){
      return this.isRRHH || this.isManagement
    },
    isNotRRHHorManagement(){
      return !this.isRRHH && !this.isManagement
    },
    asChief() {
      // If is rrhh or management and tabRole is chief will access as chief role, in the other modules that are not rrhh or management will access as chief too 
      return ((this.isRRHH || this.isManagement) && this.$route.meta.tabRole == 'chief') || this.isNotRRHHorManagement
    },
  },
  data() {
    return {
      filter:this.status==2?filtersDataRejected:filtersDataApproved,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      isBusy: false,
      totalRows: 0,
      startPage: null,
      toPage: null,
      sortBy: "",
      sortDesc: true,
      fieldsApproved: [
        {
          key: "employee_name",
          label: "Employee",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "date_mark",
          label: "Date",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "overtime",
          label: "Overtime",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "justification",
          label: "Justification",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "motive",
          label: "Motive",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "tracking",
          label: "tracking",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "process_overtime",
          label: "Status",
          thClass: "text-center",
          visible: true,
        },
      ],
      listApprovedOvertime: [],
      showUpload: false,
      showTracking: false,
      showAll: false,
      titleModal: '',
      actionJustification: null,
      showUploadMedicalRest: false,
      userIdSelected: null,
    };
  },
  created() {
    this.titleModal = this.status === 1? 'Justifications Approved' : 'Justifications Disapproved'
    const observationIndex = this.fieldsApproved.findIndex(
      (field) => field.key === "motive"
    );
    this.fieldsApproved[observationIndex].visible = this.status !== 1 // will be visible if disapproved
  },
  mounted() {
    this.userIdSelected = this.dataEmployee.user_id
  },
  watch: {
    'dataEmployee.user_id'() {
      this.userIdSelected = this.dataEmployee.user_id
    }
  },
  methods: {
    async closeModalJustification() {
      this.showUpload = false;
    },
    async refreshTable() {
      this.$refs.refOvertimeApproved.refresh();
    },
    closeTrackingMedicalRest() {
      this.showTracking = false;
    },
    showModalTracking(idJustification) {
      this.idJustification = idJustification;
      this.showTracking = true;
    },
    async overtimeApprovedProvider(ctx) {
      const userId = this.dataEmployee ? this.dataEmployee.user_id : null ;
      try {
        const params = {
          name_text: this.filterPrincipal.model,
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          sortBy: ctx.sortBy,
          sortOrder: ctx.sortDesc ? "ASC" : "DESC",
          orderBy: 10,
          order: "DESC",
          user_id: userId,
          filterDay: this.dataEmployee!=null?this.valueFilterDay:null,
          filterWeekStart:this.filter[0].model!=null?this.filter[0].model:this.dataEmployee!=null?this.valueFilterWeekStart:null,
          filterWeekEnd:this.filter[1].model!=null?this.filter[1].model:this.dataEmployee!=null?this.valueFilterWeekEnd:null,
          // obtener mes actual
          filterMonth: this.dataEmployee!=null?this.valueFilterMonth:moment().format("MM"),
          filterYear: this.dataEmployee!=null?this.valueFilterYear:moment().format("YYYY"),
          statusForChief: this.status, // 1 approved, 2 disapproved
          statusForRrhhManagement: 2, // specially for the call to the service of RRhh and management, 2 means: approved justifications for the table of RRhh and management
          moduleId: this.moduleId,// specially for the call to the service of RRhh and management
          type_justification:this.filter[2].model, 
          process_overtime:this.filter[3].model
        };
        let data = ''
        // if it's accessing as a chief or if I want to see the disapproved hours (status 2)
        if (this.asChief || this.status == 2) {
          data = await DashboardServices.getListApprovedOvertime(params);
        } else {
          data = await DashboardServices.getListPendingApprovedOvertimeRRHhManagement(params);
        }

        if (data.status === 200) {
          this.listApprovedOvertime = data.data.data;
          this.totalRows = data.data.total;
          this.startPage = data.data.from || 0;
          this.toPage = data.data.to || 0;
          this.paginate.currentPage = data.data.current_page;
          this.paginate.perPage = data.data.per_page;
          this.listApprovedOvertime.showAll = false;
          this.perPage = 10;
          this.listApprovedOvertime.forEach((item)=>{
            this.$set(item,"opened",false)
          })
          return this.listApprovedOvertime || [];
        }
      } catch (error) {
        this.showErrorSwal(error);
        console.error(error);
      }
    },
    showJustification(data) {
      if(data.type_justification==5){
        return;
      }
      this.showUpload = true;
      this.dataJustification = data;
      this.actionJustification = "Show";
      this.userIdSelected = data.employee_id
    },
    showMedicalRest(data) {
      this.showUploadMedicalRest = true;
      this.userIdSelected = data.employee_id
      
      this.dataJustification = data;
      this.dataJustification.id = data.id_justificationb;
    },
    closeUploadMedicalRest() {
      this.showUploadMedicalRest = false;
    },
    returnProcessOvertimeColor(processOvertimeNum){
      if (processOvertimeNum == 2) {
        return 'light-warning'
      } else if([4,6].includes(processOvertimeNum)){
        return 'light-success'
      }else {
        return 'light-danger'
      }
    },
  },
};
</script>
